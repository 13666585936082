import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public idUser = '';
  private jwt = '';
  private role = '';
  private client_id = '';
  private value = false;
  private behaviorLog = new BehaviorSubject(this.value);

  constructor(private cookieService: CookieService, private oauthService: OAuthService) { }

  SetLogged(value: boolean) {
    this.behaviorLog.next(value);
  }

  GetLogged() {
    return this.behaviorLog.asObservable();
  }

  GetLoggedValue(): boolean {
    return this.behaviorLog.value;
  }

  public getJwt() {
    //if (this.jwt.length === 0)
    this.jwt = this.oauthService.getAccessToken();

    if (this.jwt && this.jwt !== 'null' && this.jwt !== 'undefined') {
      const jwtHelper = new JwtHelperService();
      if (!jwtHelper.isTokenExpired(this.jwt)) {
        this.role = jwtHelper.decodeToken(this.jwt).role;
        this.client_id = jwtHelper.decodeToken(this.jwt).client_id;
      } else this.jwt = '';
    } else this.jwt = '';
    return this.jwt;
  }

  public getRole() {
    this.getJwt();
    return this.role;
  }

  public getClientId() {
    this.getJwt();
    return this.client_id;
  }

  public isJwtExpired() {
    this.getJwt();
    if (this.jwt === '') return true;
    const jwtHelper = new JwtHelperService();
    return jwtHelper.isTokenExpired(this.jwt);
  }

  public getIdEmployee() {
    this.getJwt();
    let idEmployee = null;
    if (!this.idUser && this.jwt !== '') {
      const jwtHelper = new JwtHelperService();
      idEmployee = jwtHelper.decodeToken(this.jwt).EmployeeId;
    }
    return idEmployee;
  }

  public getIdUser() {
    this.getJwt();
    if (!this.idUser && this.jwt !== '') {
      const jwtHelper = new JwtHelperService();
      this.idUser = jwtHelper.decodeToken(this.jwt).unique_name;
    }
    return this.idUser;
  }

  login(token: string) {
    //this.cookieService.set(environment.jwtName, token, {
    //  expires: 7,
    //  path: '/',
    //  domain: environment.domainCookie,
    //  secure: true,
    //  sameSite: 'Strict'
    //});

    this.SetLogged(true);
  }

  logout() {
    this.cookieService.deleteAll();
    this.jwt = '';
    this.SetLogged(false);
  }
}
